import React, { useEffect, useContext } from "react"
import NavigationContext from "../../context/navigationContext"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Table from 'react-bootstrap/Table';

function TeamsScreen() {
  const { selectedNavigation } = useContext(NavigationContext)
  
  return (
    selectedNavigation === "navigation-teams" && <div className="sportplaces-container">
      <Navbar>
        <Container>
          <Nav className="sportplaces-menu">
          <Navbar.Brand >Your Teams</Navbar.Brand>
            <Nav.Link>Team 1</Nav.Link>
            <Nav.Link className="bg-success">Team 2</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Table responsive>
        <thead>
            <tr>
            <th>Players</th>
            {Array.from({ length: 7 }).map((_, index) => (
                <th key={index}>Player {index}</th>
            ))}
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>Names</td>
            {Array.from({ length: 7 }).map((_, index) => (
                <td key={index}>Player {index}</td>
            ))}
            </tr>
        </tbody>
        </Table>
    </div>
  );
}

export default TeamsScreen;