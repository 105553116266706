import React, { useEffect, useContext } from "react"
import LoginContext from "../../context/loginContext"
import Dropdown from "react-bootstrap/Dropdown"

function UserProfile() {
  const { processLogout } = useContext(LoginContext)

  return  (
    <Dropdown>
      <Dropdown.Toggle variant="secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
        </svg>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>Profile</Dropdown.Item>
        <Dropdown.Item onClick={processLogout}>Log out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default UserProfile;