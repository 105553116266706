import React, { useEffect, useContext } from "react"
import { Container, Row, Button } from "react-bootstrap"
import LoginContext from "../../context/loginContext"
import Navigation from "./navigation"

function Header() {
  const { processLogout, isUserSignedIn } = useContext(LoginContext)

  return  (
    <>
        <nav className="navbar navbar-light">
            <a className="navbar-brand" href="#">
            <img src={require('../assets/sb-logo.png')} className="d-inline-block align-top" alt="logo"/>
            SportsBooking   
            </a>
            <Navigation></Navigation> 
        </nav>
    </>
    
  )
}

export default Header;