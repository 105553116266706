import React, { useEffect, useContext } from "react"
import NavigationContext from "../../context/navigationContext"
import SportplacesContext from "../../context/sportplacesContext"
import TimelineContext from "../../context/timelineContext"
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Spinner from '../shared/spinner'
import TimelineSlots from "./timelineSlots"
import TimelineDates from "./timelineDates"

function Sportplaces() {
  const { selectedNavigation } = useContext(NavigationContext)
  const { isLoadingSportplaces, sportplaces } = useContext(SportplacesContext)
  const { selectedSportplace } = useContext(SportplacesContext)
  const { handleSportplaceSelect } = useContext(SportplacesContext)
  const { isLoadingGrounds, grounds } = useContext(SportplacesContext)
  const { selectedDate } = useContext(TimelineContext)

    return ( 
        <div className="row">
        <div className="col-sm-12 col-md-3 col-xl-2" id="sportplaces-container">
          <Navbar>
              <Nav className="sportplaces-menu">
              <Navbar.Brand >Your Sportplaces</Navbar.Brand>
                {sportplaces.map((sportplace) => (
                   <Nav.Link
                    key={sportplace._id}
                    onClick={() => handleSportplaceSelect(sportplace._id, null)}
                    className={selectedSportplace === sportplace.id && "activated bg-success"}>
                      {sportplace.name}
                    </Nav.Link>
                ))}
              </Nav>
          </Navbar>
        </div> 
        <div className="col-sm-12 col-md-9 col-xl-10" >
          <div id="timeline-dates-container">
            <div className="row">
              {/* <TimelineDatesNavigation></TimelineDatesNavigation> */}
              <div className="col-sm-1 col-xs-2">
                <input className="btn btn-success timeline-dates-navigation" type="button" value="←" id="timeline-dates-move-left"/>
              </div>
             
              {!isLoadingGrounds ? <TimelineDates/> : <Spinner isHidden={true}/> }
              <div className="col-sm-1 col-xs-2">
                <input className="btn btn-success timeline-dates-navigation" type="button" value="→" id="timeline-dates-move-right"/> 
              </div>
              
            </div> 
          </div> 
          <div id="timeline-slots-container">
            {!isLoadingGrounds ? <TimelineSlots/> : <Spinner /> }
          </div> 
        </div>
      </div>
    ) 
}

export default Sportplaces;
