import React, { useContext, useState, Component } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "./components/shared/footer"
import LoginForm from "./components/login"
import Homepage from "./components/homepage"
import { LoginProvider } from "./context/loginContext"
import { NavigationProvider } from "./context/navigationContext"

import { BrowserRouter, Routes, Route } from "react-router-dom"

import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"

function App() {

  return (
    <LoginProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" 
            element={
              (<>
                <LoginForm />
                  <Homepage />
                
              </>)}>

            </Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </LoginProvider>
  )
}

export default App