import React, { useEffect, useContext } from "react"
import { Container, Row, Button } from "react-bootstrap"
import LoginContext from "../../context/loginContext"
import Dropdown from "react-bootstrap/Dropdown"

function Notifications() {
  const { processLogout, isUserSignedIn } = useContext(LoginContext)

  return  (
    <Dropdown>
    <Dropdown.Toggle variant="secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
        </svg>
    </Dropdown.Toggle>

    <Dropdown.Menu>
    <Dropdown.Item href="#/action-1">Notification 1</Dropdown.Item>
    <Dropdown.Item href="#/action-2">Notification 2</Dropdown.Item>
    <Dropdown.Item href="#/action-3">Notification 3</Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown>
  )
}

export default Notifications;