import spinner from '../../assets/spinner.gif'

function Spinner(props) {
  return (
    <img
      src={spinner}
      className={props.isHidden && "hidden"}
      alt='Loading...'
      style={{ width: '100px', margin: 'auto', display: 'block' }}
    />
  )
}

export default Spinner