import { Link } from "react-router-dom"

function Footer() {
  
    return (
        <footer className="footer text-center text-md-start bg-white text-muted">
            <section className="">
                <div className="text-center text-md-start">
                <div className="row">
                    <div className="col-md-10 col-lg-10 col-xl-10 mx-auto ml-4">
                        <p className="">
                            <i className="fas fa-gem me-3 text-secondary"></i>SportsBooking © 2022
                        </p>
                    </div>
                    <div className="col-md-1 col-lg-1 col-xl-1 mx-auto">
                        <Link className="text-success" to='/'> About </Link>
                    </div>
                    <div className="col-md-1 col-lg-1 col-xl-1 mx-auto">
                        <Link className="text-success" to='/'> Support </Link>
                    </div>
                </div>
                </div>
            </section>
        </footer>
    )
  }

  export default Footer