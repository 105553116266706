import moment from "moment"
import React, { useEffect, useRef, useContext, cloneElement } from "react";
import { Timeline } from "vis-timeline";
import { DataSet } from "vis-data";
import TimelineContext from "../../context/timelineContext"

function getZoomOptions(container){
    let columnWidth = 100
    let oneHourInMilliseconds = 1000 * 60 * 60 * 24
    let visibleHours = Math.floor(container.clientWidth / columnWidth)
    let zoom = oneHourInMilliseconds * visibleHours
    
    return { zoomMin: zoom, zoomMax: zoom }
}

const TimelineDates = () => {
    const { selectedDate, setSelectedDate } = useContext(TimelineContext)
    const { focusedItem, setFocusedItem } = useContext(TimelineContext)
    const { handleTimelineDatesSelect } = useContext(TimelineContext)

    let items = new DataSet()
    let minDate = moment(new Date()).subtract(30, "d")
    let maxDate = moment(new Date()).add(60, "d")
    let minDateToIterate = moment(new Date()).subtract(30, "d").toDate()
    let todayItemId;

    for (let index = 0; index < 90; index++) {
        let currentDay = moment(minDateToIterate).add(index, "d")
        let isToday = currentDay.isSame(new Date(), "day");
        let currentDate = {
            id: index,
            content: isToday? "TODAY" : currentDay.format("ddd[\n]DD.MM"),
            className: moment(currentDay).isSame(moment(selectedDate), "day") && "selected-date", 
            start: currentDay.set({hour: 1, minute: 0, second: 0}).toDate(),
            end: currentDay.set({hour: 22, minute: 59, second: 59}).toDate()
        }

        if(isToday){
            todayItemId = index;
        }
        
        items.add(currentDate)
    }
    
    let options = {
        orientation: {
            axis: 'bottom'
        },
        timeAxis: {
            scale: "day"
        },
        min: minDate.toISOString(),
        max: maxDate.toDate(),
        width: "100%",
        height: "75px",
        stack: false,
        showMajorLabels: false,
        showMinorLabels: false,
        showCurrentTime: false,
        selectable: true,
        type: "range",
        format: {
            minorLabels: {
                day: "DD.MM"
            }
        }
    }

    let timeline

	const visJsRef = useRef("timeline-dates-container");

	useEffect(() => {
        let container = document.getElementById('timeline-dates-container')
        let zoomOptions = getZoomOptions(container)

        if(visJsRef.current.innerHTML.length) {
            visJsRef.current.innerHTML = ""
        }

        options = Object.assign(options, zoomOptions)
        
        timeline =  
            visJsRef.current &&
            new Timeline(visJsRef.current, items, null, options);

        if(focusedItem){
            timeline.setSelection(focusedItem.id, 
                { focus: true, animation: {  duration: 500, easingFunction: "easeInQuad" }})
        } else {
            timeline.setSelection(todayItemId, 
                { focus: true, animation: {  duration: 500, easingFunction: "easeInQuad" }})
        }
        
        

        document.getElementById("timeline-dates-move-left").onclick = () => {move(0.6)};
        document.getElementById("timeline-dates-move-right").onclick = () => {move(-0.6)};

        timeline.on("select", (event) => { 
            let selectedItem = items.get(event.items[0])
            let selectedItemDate = selectedItem.start
            console.log("====")
            console.log(selectedItem)
            
            timeline.setSelection(selectedItem.id, { focus: true, animation: { duration: 500, easingFunction: "easeInQuad" } })
            setFocusedItem(selectedItem)
            setSelectedDate(selectedItemDate)
            handleTimelineDatesSelect(selectedItemDate)
        })

	}, [visJsRef, items, options]);
       

    function move(percentage){
        let range = timeline.getWindow();
        let interval = range.end - range.start;
      
        timeline.setWindow({
          start: range.start.valueOf() - interval * percentage,
          end: range.end.valueOf() - interval * percentage,
        });
    }

    

    window.addEventListener('resize', ()=>{
        let container = document.getElementById('timeline-dates-container')

        timeline.setOptions(getZoomOptions(container))
        timeline.setWindow({start: null, end: null, animation: true})
    })
	return ( <div className="col-sm-10 col-xs-8">
            <div ref={visJsRef} id="timeline-dates"/>
        </div> )
};

export default TimelineDates;