import { createContext, useState, useEffect } from 'react'
import {login} from '../api/index'

const LoginContext = createContext()


export const LoginProvider = ({children}) => {
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [errorText, setErrorText] = useState(null);

    const processLogin = async ({username, password}) => {
        let loginResult = await login({ username: username.value, password: password.value });

        setErrorText(false);

        // Compare user info
        if (loginResult) {
            if (loginResult.result && loginResult.result.token) {
              
              localStorage.setItem("name", username.value);
              localStorage.setItem("token", loginResult.result.token);
  
              setIsUserSignedIn(true)
            } else {
              setErrorText(loginResult.statusText || 'Login Error');
              // Invalid password
            }
          } else {
              setErrorText('Login Error');
          }
    }

    const processLogout = async () => {
        localStorage.removeItem("name");
        localStorage.removeItem("token");
    
        setIsUserSignedIn(false)
    }

    return (
        <LoginContext.Provider
          value={{
            isUserSignedIn,
            errorText,
            processLogin,
            processLogout
          }}
        >
          {children}
        </LoginContext.Provider>
      )
}

export default LoginContext