import { createContext, useState, useEffect, useContext } from 'react'

const NavigationContext = createContext()

export const NavigationProvider = ({children}) => {
    const [selectedNavigation, setSelectedNavigation] = useState("")

    useEffect(() => {
      setSelectedNavigation("navigation-sportplaces")
    }, [])

    const handleNavigationSelect = async (a) => {
      setSelectedNavigation(a.target.id)
    }

    return (
        <NavigationContext.Provider
          value={{
            selectedNavigation,
            handleNavigationSelect
          }}
        >
          {children}
        </NavigationContext.Provider>
      )
}

export default NavigationContext