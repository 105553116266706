import React from "react"
import { useContext, useEffect, useState } from 'react'
import LoginContext from "../../context/loginContext"

function LoginForm(){
    const { processLogin, errorText, isUserSignedIn } = useContext(LoginContext)
    const handleSubmit = async (event) => {
        //Prevent page reload
        event.preventDefault();
      
        let { username, password } = document.forms[0];

        await processLogin({username, password})
    };

    return (
        !isUserSignedIn &&
        <div>
            <div className="container">
            <form className="login-form" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-12">
                        <img src={require('../assets/sb-logo.png')} className="sb-logo" ></img>
                    </div>    
                </div>
                <div className="row">
                    <div className="col-sm-3 col-md-4"></div>
                    <div className="col-sm-6  col-md-4">
                        <div className="form-group mt-5">
                            <label className="icon-input-username">
                                <input
                                type="text"
                                className="form-control mt-1"
                                placeholder="Username"
                                name="username"
                                required
                                />
                            </label>
                        </div>
                        <div className="form-group mt-2">
                            <label className="icon-input-password">
                                <input
                                type="password"
                                className="form-control mt-1"
                                placeholder="Password"
                                name="password"
                                required
                                />
                            </label>
                        </div>
                        <div className="form-group mt-4">
                            <div className="sb-div-center gap-2 mt-4">
                                <button type="submit" className="btn btn-success sb-padding-15">
                                Login
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3 col-md-4"></div>
                </div>
                {errorText && (
                    <div className="row mt-3">
                        <div className="col-sm-3 col-md-4"></div>
                        <div className="col-sm-6 col-md-4 alert alert-danger text-center">
                            <p>{errorText}</p>
                        </div>
                        <div className="col-sm-3 col-md-4"></div>
                    </div>
                    )}
            </form>
            </div>
        </div>
    )
}

export default LoginForm