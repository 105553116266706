import React, { useEffect, useContext } from "react"
import { Container, Row, Button } from "react-bootstrap"
import { NavigationProvider } from "../../context/navigationContext"
import { TimelineProvider } from "../../context/timelineContext"
import LoginContext from "../../context/loginContext"
import { SportplacesProvider } from "../../context/sportplacesContext"
import Header from "./header"
import SportplacesScreen from "./sportplacesScreen"
import TeamsScreen from "./teamsScreen"

function Homepage() {
  const { isUserSignedIn } = useContext(LoginContext)

  return  (
    isUserSignedIn && 
      <NavigationProvider>
        <Container fluid>
          <Header></Header>
          
            <SportplacesProvider>
              <TimelineProvider>
                <SportplacesScreen>
                </SportplacesScreen>
                
                <TeamsScreen>
                </TeamsScreen>
              </TimelineProvider>
            </SportplacesProvider>
        </Container>
      </NavigationProvider>
  )
}

export default Homepage;