import React, { useEffect, useContext } from "react"
import Sportplaces from "./sportplaces"
import NavigationContext from "../../context/navigationContext"
import SportplacesContext from "../../context/sportplacesContext"
import Spinner from '../shared/spinner'

function SportplacesScreen() {
  const { selectedNavigation } = useContext(NavigationContext)
  const { isLoadingSportplaces, sportplaces } = useContext(SportplacesContext)

  if(selectedNavigation === "navigation-sportplaces") {
    return ( !isLoadingSportplaces ? <div className="container" id="sportplaces-screen-container">
        <Sportplaces></Sportplaces>
    </div> : <Spinner/> )
  }   
}

export default SportplacesScreen
