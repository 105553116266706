import React, { useEffect, useContext } from "react"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavigationContext from "../../context/navigationContext"
import Notifications from "./notifications"
import UserProfile from "./userProfile"
import 'bootstrap/dist/css/bootstrap.min.css';

function Navigation() {
  const { handleNavigationSelect } = useContext(NavigationContext)
  const { selectedNavigation } = useContext(NavigationContext)

  return  (
    <div className="navigation">
        <Navbar>
            <Nav className="">
                <Nav.Link 
                    id="navigation-sportplaces" 
                    onClick={handleNavigationSelect} 
                    className={selectedNavigation === "navigation-sportplaces" && "activated bg-success"}>
                        Sportplaces
                </Nav.Link>
                <Nav.Link 
                    id="navigation-teams"
                    onClick={handleNavigationSelect}
                    className={selectedNavigation === "navigation-teams" && "activated bg-success"}>
                        Teams
                </Nav.Link>
            </Nav>
        </Navbar>
        <Notifications></Notifications>
        <UserProfile></UserProfile>
    </div>
  )
}

export default Navigation;