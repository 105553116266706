import moment from 'moment'
import { createContext, useState, useEffect } from 'react'
import { getOwnerSportplaces } from '../api/index'
import { getSportplaceGrounds } from '../api/index'

const SportplacesContext = createContext()

export const SportplacesProvider = ({children}) => {
    const [isLoadingSportplaces, setIsLoadingSportplaces] = useState(true);
    const [isLoadingGrounds, setIsLoadingGrounds] = useState(true);
    const [sportplaces, setSportplaces] = useState({});
    const [grounds, setGrounds] = useState({});
    const [selectedSportplace, setSelectedSportplace] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        fetchSportplaces()
      }, [])

    async function fetchSportplaces(){
        let token = localStorage.getItem("token");
        let response;

        if(sportplaces && sportplaces.length){
            setSportplaces(sportplaces)
        } else {
            response = await getOwnerSportplaces(token);
            
            if(response.result && response.result.length){
                setSelectedSportplace(response.result[0]._id)
                setSportplaces(response.result)
                setIsLoadingSportplaces(false)

                fetchGrounds(response.result[0]._id)                
            } else {
                setSportplaces(null)
            }
        }
        
        setIsLoadingSportplaces(false)
    }

    async function handleSportplaceSelect(id, date){
        if(id){
            setSelectedSportplace(id)
        }

        if(date){
            setSelectedDate(date)
        }

        fetchGrounds(id || selectedSportplace, date || selectedDate)
    }

    async function fetchGrounds(selectedSportplace, date){
        let token = localStorage.getItem("token");
        let dateForRequest = date || moment().format("DD.MM.YYYY");
        let response = await getSportplaceGrounds(token, selectedSportplace, dateForRequest);
        console.log(response)
        if(response.result && response.result.groundSchedules && response.result.groundSchedules.length){
            setGrounds(response.result.groundSchedules)
        } else {
            setGrounds(null)
        }
        
        setIsLoadingGrounds(false)
    }

    return (
        <SportplacesContext.Provider
          value={{
            isLoadingSportplaces,
            isLoadingGrounds,
            sportplaces,
            grounds,
            handleSportplaceSelect,
            selectedSportplace
          }}
        >
          {children}
        </SportplacesContext.Provider>
      )
}

export default SportplacesContext

