import moment from "moment"
import React, { useEffect, useRef, useContext } from "react";
import { Timeline } from "vis-timeline";
import { DataSet } from "vis-data";
import jwt_decode from "jwt-decode";
import SportplacesContext from "../../context/sportplacesContext";

function getZoomOptions(container){
    let columnWidth = 100
    let oneHourInMilliseconds = 1000 * 60 * 60
    let visibleHours = Math.floor(container.clientWidth / columnWidth)
    let zoom = oneHourInMilliseconds * visibleHours

    return { zoomMin: zoom, zoomMax: zoom }
}

function calculateGroupContent(groupContent){
    let longestWord = 0;
    let result = {
        groupContent: "",
        groupStyles: ""
    }
    
    let words = groupContent.split(" ")

    words.forEach((w, index)=>{
        if(w.length > longestWord){
            longestWord = w.length
        }

        if(index == (words.length - 1)){
            result.groupContent += w.length > 10 ? w.slice(0, 7 - w.length) + "..." : w
        } else {
            result.groupContent += w.length > 10 ? w.slice(0, 7 - w.length) + "... " : w + " "
        }
    })

    if(longestWord >= 10){
        result.groupStyles += "max-width: 98px;"
    } else if (longestWord > 6 && longestWord < 10){
        result.groupStyles += "max-width: 98px;"
    } else if (longestWord > 3 && longestWord < 5){
        result.groupStyles += "min-width: 60px; max-width: 88px; margin-right: 10px"
    } else {
        result.groupStyles += "min-width: 30px; max-width: 98px; margin-right: 40px"
    }

    if(result.groupContent.length > 25) {
        result.groupContent = result.groupContent.slice(0, 22 - result.groupContent.length) + "..."
        result.groupStyles += "max-width: 98px"
    }

    return result;
}

const TimelineSlots = () => {
    const { grounds } = useContext(SportplacesContext)

    let groups = new DataSet()
    let items = new DataSet()
    let firstTimeslot
    let lastTimeslot
    let focusedItem
    
    grounds.forEach((ground)=>{
        let calculatedGroupContent = calculateGroupContent(ground.showName)
        
        let currentGroup = {
            id: ground.groundId,
            content: calculatedGroupContent.groupContent,
            style: calculatedGroupContent.groupStyles,
            title: ground.showName
        }

        groups.add(currentGroup)

        ground.schedule.forEach((timeslot, index, arr)=>{
            let start = moment(parseInt(timeslot.date)).add(5, "m").toDate()
            let end = moment(parseInt(timeslot.date)).add(55, "m").toDate()
            let token = localStorage.getItem("token");
            let decoded = jwt_decode(token)
            let className;
    
            if(timeslot.userId){
                className = timeslot.userId === decoded._id ? "booked-by-current-user" : "booked-by-other-user"
            }
            
            let currentTimeslot = {
                id: timeslot.id,
                group: ground.groundId,
                content: "+",
                className: className,
                start: start,
                end: end
            }

            if(!focusedItem){
                focusedItem = timeslot.bookedFrom ? timeslot.id : null
            }

            if(!firstTimeslot) {
                firstTimeslot = start
            }

            if(!arr[index + 1]) {
                lastTimeslot = end
            }

            items.add(currentTimeslot)
        })

    })

    let options = {
        orientation: {
            axis: 'top'
        },
        timeAxis: {
            scale: "hour"
        },
        min: firstTimeslot,
        max: lastTimeslot,
        width: "100%",
        height: "120px",
        stack: false,
        showMajorLabels: false,
        showCurrentTime: false,
        selectable: true,
        type: "range",
        format: {
            minorLabels: {
                minute: "HH:mm",
                hour: "HH:mm"
            },
            majorLabels: {
                hour: "ddd DD.MM",
                day: "dd DD.MM"
            },
        }
    }

    let timeline

	const visJsRef = useRef("timeline-slots-container");

	useEffect(() => {
        let container = document.getElementById('timeline-slots-container')
        let zoomOptions = getZoomOptions(container)

        if(visJsRef.current.innerHTML.length) {
            visJsRef.current.innerHTML = ""
        }

        options = Object.assign(options, zoomOptions)
        
        timeline = visJsRef.current &&
            new Timeline(visJsRef.current, items, groups, options);
	}, [visJsRef, items, options]);

    window.addEventListener('resize', ()=>{
        let container = document.getElementById('timeline-slots-container')

        timeline.setOptions(getZoomOptions(container))
        timeline.setWindow({start: null, end: null, animation: true})
    })
	return <div ref={visJsRef} id="timeline-slots"/>;
};

export default TimelineSlots;