let appName = process.env.REACT_APP_HEROKU_INSTANCE
let baseUrl = appName ? `https://${appName}.herokuapp.com` : 'http://localhost:8080'

export async function login({ username, password }) {
    return await fetch(`${baseUrl}/auth/webLogin`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        // If request is not successful, display error message
        if (!response.ok) {
          throw new Error("HTTP status " + response.status);
        }
  
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
}

export async function getOwnerSportplaces(token) {
  return await fetch(`${baseUrl}/sportPlaces`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "token": token
    }
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getSportplaceGrounds(token, sportPlaceId, day){
  return await fetch(`${baseUrl}/getSchedule`, {
    method: "POST",
    body: JSON.stringify({ sportPlaceId, day }),
    headers: { 
      "Content-Type": "application/json",
      "token": token
    }
  })
    .then((response) => {
      // If request is not successful, display error message
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}